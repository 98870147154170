import React  from 'react';


//components
import {Layout} from '../../components/Layout';

export default function List(props){



    return (
        <Layout>
            
            <div className="filters">
                <h2>Bem vindo ao painel administrativo Super Beneficios!</h2>
            </div>
            <hr />

        </Layout>
    );

};