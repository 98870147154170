import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

//images
import logo from '../../../assets/logo.svg';

//css
import './styles.scss';

//icons
import { Icon } from '@iconify/react';
import iconHome from '@iconify/icons-cil/monitor';
import iconLogout from '@iconify/icons-icomoon-free/exit';
import iconWebsite from '@iconify/icons-dashicons/admin-site-alt3';
import iconBlog from '@iconify/icons-carbon/blog';


export function Layout(props){

    const history = useHistory();
    // const [navHide, setNavhide] = useState('navHide');
    // const [navHide, setNavhide] = useState(null);

    useEffect(() => {
    }, [props]);

    // function navClick()
    // {
    //     if( navHide === 'navHide' )
    //     {
    //         setNavhide(null);
    //     } else {
    //         setNavhide('navHide');
    //     }
    // }

    function handleLogout(){
        localStorage.clear();
        history.push("/");
    }

    return (
        <>
        <div id="nav">
            
            {/* <div id="bar" onClick={(e) => navClick()}>
                <Icon icon={iconBars} />
            </div> */}
            
            <img src={logo} alt="Kilima" style={{width:'220px', margin:'20px 20px 0 20px', filter:'brightness(0) invert(1)'}} />
            
            {/* <Link to="/profile" id="user">
                <span className="image" style={{backgroundImage: `url('${profile.foto}')`}}></span>
                <span className="description">
                    <span className="name">{profile.nome}</span>
                    <span className="type">Usuário</span>
                </span>
            </Link> */}

            <nav className="pt-3">
                <Link to="/home" className={window.location.pathname === "/home" ? "active" : null}><Icon icon={iconHome} /> <span>Home</span></Link>

                <Link to="/super-casa" className={window.location.pathname === "/super-casa" ? "active" : null}><Icon icon={iconBlog} /> <span>Super Casa</span></Link>
                <Link to="/super-saude" className={window.location.pathname === "/super-saude" ? "active" : null}><Icon icon={iconBlog} /> <span>Super Saúde</span></Link>
                <Link to="/super-pet" className={window.location.pathname === "/super-pet" ? "active" : null}><Icon icon={iconBlog} /> <span>Super Pet</span></Link>
                <Link to="/super-odonto" className={window.location.pathname === "/super-odonto" ? "active" : null}><Icon icon={iconBlog} /> <span>Super Odonto</span></Link>
                <Link to="/consultor" className={window.location.pathname === "/consultor" ? "active" : null}><Icon icon={iconBlog} /> <span>Consultor</span></Link>

                <a href="http://livesaude.tropa.digital" rel="noopener noreferrer" target="_blank"><Icon icon={iconWebsite} />Ir para o site</a>
                <button onClick={handleLogout}><Icon icon={iconLogout} /><span>Logout</span></button>
            </nav>
        </div>

        <div id="container" className={`p-3`}>
            {/* <img src={logo} className="logo" alt="ItGeo" /> */}
            {props.children}
        </div>
        </>
    );

};