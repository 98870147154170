import axios from "axios";

var urlApi = "";
if(process.env.NODE_ENV === 'development') {
    // urlApi = 'http://localhost:3000/'
    urlApi = 'https://superbeneficios.guilhermegonzales.com.br/';

} else if (process.env.NODE_ENV === 'test') {
    // urlApi = 'http://localhost:3000/'
    urlApi = 'https://superbeneficios.guilhermegonzales.com.br/'

} else {
    urlApi = 'https://api-site-super-beneficios-bxcu5etpra-uc.a.run.app/'
}

const api = axios.create({
    baseURL: urlApi
});

if( localStorage.getItem('token') )
{
    api.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
}

export default api;
