import React, { useState } from 'react';
import api from '../../services/api';

import './styles.scss';

//images
import logo from '../../assets/logo.svg';

export default function Login(props) {
    
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    async function handleLogin(e)
    {
        e.preventDefault();

        try
        {
            let response = await api.post('/admin/login', {login: login, senha: password});

            //se der erro na autenticação
            if( response.status !== 200 || response.error === true || !response.data ) {
                throw response.data.message;
            }

            console.log(response);

            localStorage.setItem('login', login);
            localStorage.setItem('token', response.data);
            localStorage.setItem('operador', false);

            window.location = "/home";

        } catch(err){
            alert("Login ou senha inválida");
        }
    }

    return (
        <div id="pageLogin">

            <div className="form">

                <form onSubmit={handleLogin}>

                    <img src={logo} alt="Kilima"  />

                    <label>
                        <span>Login</span>
                        <input type="text" value={login} onChange={e => setLogin(e.target.value)} autoFocus />
                    </label>

                    <label>
                        <span>Senha</span>
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                    </label>
                    
                    <button type="submit">Entrar</button>

                </form>

            </div>

            <div className="footer">
                <p>Desenvolvido por <a href='tropa.digital' target="_blank" >tropa.digital</a></p>
            </div>
        </div>
        
    );
}