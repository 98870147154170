import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

//pages
import Login from './pages/Login';

import SuperCasa from './pages/SuperCasa/list';
import SuperPet from './pages/SuperPet/list';
import SuperSaude from './pages/SuperSaude/list';
import SuperOdonto from './pages/SuperOdonto/list';
import Consultor from './pages/Consultor/list';


import HomeList from './pages/Home/list';



export default function Routes(props){
    
    function PrivateRoute({ children, ...rest })
    {

      return (

        <Route
          {...rest}
          render={({ location }) =>
            window.localStorage.getItem('token') ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location }
                }}
              />
            )
          }
        />

      )
    }

    return (
        <Router>
            <Switch>

                <Route path="/" exact component={Login}/>


                <PrivateRoute exact path="/super-casa">
                    <Route component={SuperCasa} />
                </PrivateRoute>

                <PrivateRoute exact path="/super-odonto">
                    <Route component={SuperOdonto} />
                </PrivateRoute>

                <PrivateRoute exact path="/super-pet">
                    <Route component={SuperPet} />
                </PrivateRoute>

                <PrivateRoute exact path="/super-saude">
                    <Route component={SuperSaude} />
                </PrivateRoute>

                <PrivateRoute exact path="/home">
                    <Route component={HomeList} />
                </PrivateRoute>
                <PrivateRoute exact path="/consultor">
                    <Route component={Consultor} />
                </PrivateRoute>


                <Route path="*">
                  <h1>404</h1>
                </Route>

            </Switch>
        </Router>
    );
}